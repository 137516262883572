import React from "react";
import PropTypes from "prop-types";

import { graphql } from "gatsby";

import shortid from "shortid";

import "twin.macro";

import { useSeo } from "@queries";

import { Layout } from "@components/layout";
import { EventTile } from "@components/eventspage";
import { Heading, ErrorMsg } from "@components/shared";

const EventsPage = ({ data }) => {
  const { description_events } = useSeo();

  const events = data.allSanityEvent.edges.map(({ node }, idx) => ({
    ...node,
    idx,
  }));

  return (
    <Layout showNewsletter title="Wydarzenia" description={description_events}>
      <Heading as="h1" tw="text-center mb-12">
        Wydarzenia
      </Heading>
      <div tw="flex flex-wrap justify-between to-md:flex-col">
        {events && events.length ? (
          events.map(({ poster, description, datetime, title, tag, idx }) => (
            <EventTile
              key={shortid.generate()}
              poster={poster.asset.fluid}
              description={description}
              datetime={datetime}
              title={title}
              tag={tag}
              idx={idx}
            />
          ))
        ) : (
          <ErrorMsg msg="Brak najbliższych wydarzeń" />
        )}
      </div>
    </Layout>
  );
};

EventsPage.propTypes = {
  data: PropTypes.shape({
    allSanityEvent: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            poster: PropTypes.shape({
              asset: PropTypes.shape({
                fluid: PropTypes.object.isRequired,
              }).isRequired,
            }).isRequired,
            description: PropTypes.string.isRequired,
            datetime: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            tag: PropTypes.shape({
              sanityId: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        })
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query EventsPageQuery {
    allSanityEvent(sort: { order: ASC, fields: datetime }) {
      edges {
        node {
          poster {
            asset {
              fluid(maxWidth: 1280) {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
          description
          datetime(formatString: "D.MM.YY, H:mm")
          title
          tag {
            sanityId
            name
          }
        }
      }
    }
  }
`;

export default EventsPage;
