import React from "react";
import PropTypes from "prop-types";

import tw from "twin.macro";

import { Row, Heading, Image, ButtonText } from "@components/shared";

const EventTag = ({ tagId, name }) => {
  const styles = {
    EVENT: tw`bg-primary-light`,
    WARSZTATY: tw`bg-secondary-light`,
    INNE: tw`bg-tertiary-light`,
  };

  const Tag = ButtonText.withComponent("span");

  return (
    <Tag
      css={[tw`text-white inline-block px-1 py-px rounded mb-3`, styles[tagId]]}
    >
      {name}
    </Tag>
  );
};

const EventTile = ({ title, description, datetime, poster, tag, idx }) => {
  const styles = {
    EVENT: tw`text-primary bg-primary-pale`,
    WARSZTATY: tw`text-secondary bg-secondary-pale`,
    INNE: tw`text-tertiary bg-tertiary-pale`,
  };

  return (
    <article
      css={[
        tw`flex w-full mb-12`,
        styles[tag.sanityId],
        idx > 0 && tw`flex-col text-center md:w-col`,
        idx === 0 && tw`to-lg:flex-col`,
      ]}
    >
      <a
        href={poster.src}
        title="Zobacz plakat"
        target="_blank"
        rel="noreferrer noopener"
        css={(tw`block`, idx === 0 && tw`to-lg:w-full w-1/2`)}
      >
        <Image
          fluid={poster}
          alt="plakat wydarzenia"
          tw="opacity-75 hover:opacity-100 transition-opacity duration-200 ease-in"
        />
      </a>
      <div
        css={[
          tw`flex justify-between flex-col`,
          idx === 0 && tw`p-16 to-lg:w-full w-1/2`,
          idx > 0 && tw`py-8 px-8`,
        ]}
      >
        <div>
          <EventTag tagId={tag.sanityId} name={tag.name} />
          <Heading as="h3" tw="m-0 text-2xl">
            {title}
          </Heading>
          <time>{datetime}</time>
        </div>
        {idx === 0 && (
          <p tw="mt-4 lg:mb-3 text-justify tracking-wider leading-8">
            {description}
          </p>
        )}
      </div>
    </article>
  );
};
EventTile.propTypes = {
  poster: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  datetime: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.shape({
    sanityId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  idx: PropTypes.number.isRequired,
};

export default EventTile;
